.Info-Container {
    display: flex;
    align-items: center;
    padding-inline: 40px;
    padding-block: 10px;
    box-shadow: 0 0 30px rgb(0 0 0 / 0.2);
    width: 70%;
    height: 23%;
    border-radius: 30px;

    .Dot {
        min-height: 120px;
        min-width: 120px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 30px;
    }

    .Text-Container :nth-child(1) {
        line-height: 0;
    }
}
