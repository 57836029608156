@media only screen and (max-width: 1400px) {
    .Info-Container {
        display: flex;
        align-items: center;
        padding-inline: 40px;
        padding-block: 10px;
        box-shadow: 0 0 30px rgb(0 0 0 / 0.2);
        width: 88%;
        height: 25%;
        border-radius: 30px;
        text-align: start !important;
        align-self: center !important;

        .Dot {
            min-height: 120px;
            min-width: 120px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 30px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .Info-Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-inline: 30px;
        padding-block: 40px;
        box-shadow: 0 0 30px rgb(0 0 0 / 0.2);
        width: 88%;
        height: 25%;
        border-radius: 30px;
        text-align: center !important;
        align-self: center !important;

        .Dot {
            min-height: 120px;
            min-width: 120px;
            border-radius: 50%;
            margin-right: 0px;
            display: inline-block;
        }

        .Text-Container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .Text-Container :nth-child(1) {
            line-height: 1;
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }
}
