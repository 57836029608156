@media only screen and (max-width: 1400px) {
  .Leistungen {
    background-color: white;
    height: auto;
    margin-bottom: 20px;
    display: flex;
    padding-block: 20px;
    padding-inline: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    h1 {
      font-size: 3rem;
      font-weight: 900;
    }
  }

  .Points {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;

    .UpperPoints,
    .LowerPoints {
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .Kontaktieren {
    margin-top: 60px;
    width: 100%;
    height: 70px;
    border-radius: 12px;
    background-color: #2e4057;
    color: white;
    border: 3px solid #2e4057;
    font-size: 20px;
    line-height: 0;
    cursor: pointer;
    font-weight: 500;
    transition-duration: 0.4s;
    margin-bottom: 20px;
  }

  .Info-selection {
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 650px;
    gap: 30px;
    height: 100%;
    justify-content: center;
  }

  .Footer {
    display: flex;
    text-align: center;
    flex-direction: column;
    height: 100svh;
    background-image: url("../public/assets/Logo.svg");
    background-repeat: space;
    background-position: center;
    background-color: #141414;
    background-size: 120px;
    position: relative;

    .Footer-Bild {
       @media only screen and (max-height: 670px) {
        width:500px;
       }
       @media only screen and (max-height: 570px) {
        width:450px;
       }
      
      position: absolute;
      bottom: 0;
      width: 600px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }

    .Benni {
      position: absolute;
      top: 5%;
      left: 10%;
      font-size: 2.5rem;
      color: white;
      font-weight: 100;
      text-align: end;
    }

    .Paul {
      position: absolute;
      top: 5%;
      right: 10%;
      font-size: 2.5rem;
      color: white;
      font-weight: 100;
      text-align: start;
    }
  }
}



@media only screen and (max-width: 800px) {
  .Kontakt {
    margin-top: -5px;
    display: flex;
    text-align: center;
    flex-direction: column;
    height: 100svh;
    background-image: url("../public/assets/KontaktBG.png");
    background-size: cover;

    h1 {
      font-size: 4rem;
      color: white;
      line-height: 0;
      padding-top: 50px;
    }

    .Kontakt-Container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      .Text-Container {
        padding: 10px;
        width: 90%;
      }
    }

    .Small-Input {
      box-shadow: 0px 0px 50px 5px rgba(255, 255, 255, 0.2);
      color: white;
      background-color: rgba(0, 0, 0, 0.23);
      height: 60px;
      width: 100%;
      border: none;
      border-radius: 12px;
      font-size: 20px;
      padding-inline: 20px;
      box-sizing: border-box;
      outline: none;
    }

    .Big-Input {
      outline: none;
      box-shadow: 0px 0px 50px 5px rgba(255, 255, 255, 0.2);
      color: white;
      background-color: rgba(0, 0, 0, 0.23);
      height: 250px;
      width: 100%;
      border: none;
      border-radius: 12px;
      font-size: 20px;
      padding: 20px;
      resize: none;
      box-sizing: border-box;
    }

    .Button-Container {
      width: 90%;

      .Button {
        background-color: #2e4057;
        cursor: pointer;
        color: white;
        font-size: 20px;
        font-weight: 900;
        line-height: 0;
        border: none;
        width: 100%;
        border-radius: 12px;
        height: 60px;
      }
    }
  }

  .Bereiche {
    background-color: #2e4057;
    height: 200svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;

    h1 {
      font-size: 3rem;
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .Elements {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      justify-content: space-between;

      p {
        font-size: 2rem;
        text-align: start;
      }
    }

    .Element-Div {
      display: flex;
      align-items: center;
    }

    .Element-img {
      padding-right: 20px;
      height: 200px;
      user-select: none;
      pointer-events: none;
    }

    ::selection {
      background: white;
      color: #2e4057;
    }
  }

  .Footer {
    display: flex;
    text-align: center;
    flex-direction: column;
    height: 100svh;
    background-image: url("../public/assets/Logo.svg");
    background-repeat: space;
    background-position: center;
    background-color: #141414;
    background-size: 120px;
    position: relative;

    .Footer-Bild {
      position: absolute;
      bottom: 0;
      width: 600px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }

    .Benni {
      position: absolute;
      top: 0%;
      left: 5%;
      font-size: 2.5rem;
      color: white;
      font-weight: 100;
      text-align: end;
    }

    .Paul {
      position: absolute;
      top: 0%;
      right: 5%;
      font-size: 2.5rem;
      color: white;
      font-weight: 100;
      text-align: start;
    }
  }

  .Impressum {
    background-color: #2e4057;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding-block: 10px;

    h1 {
      font-size: 1rem;
      line-height: 0;
    }
    .Elements {
      display: flex;
      width: 100%;
      flex-direction: row;
      // align-items: center;
      justify-content: space-evenly;

      p {
        font-size: 10px;
        text-align: center;
      }
    }
    .Element-img {
      height: 150px;
      user-select: none;
      pointer-events: none;
    }

    ::selection {
      background: white;
      color: #2e4057;
    }
  }
}

.Info-Container {
  display: flex;
  align-items: center;
  padding-inline: 40px;
  padding-block: 10px;
  box-shadow: 0 0 30px rgb(0 0 0 / 0.2);
  width: 88%;
  height: 25%;
  border-radius: 30px;
  text-align: start !important;

  .Dot {
    min-height: 120px;
    min-width: 120px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .UpperPoints div:nth-child(2) {
    display: flex;
    justify-content: row;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    width: 130px;
    line-height: 0;
  }

  .LowerPoints div:nth-child(2) {
    display: flex;
    justify-content: row;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    width: 130px;
    line-height: 0;
  }

  .Leistungen {
    background-color: white;
    height: 300svh;
    display: flex;
    padding-block: 20px;
    padding-inline: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    h1 {
      font-size: 2.5rem;
      font-weight: 900;
    }
  }

  .Points {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;

    .UpperPoints,
    .LowerPoints {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .Bereiche {
    background-color: #2e4057;
    height: 150svh;
    padding-block: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;

    h1 {
      font-size: 2.5rem;
      line-height: 1.5;
      margin: 0;
      text-align: center;
    }
    .Elements {
      padding-bottom: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 80%;
      width: 80%;
      justify-content: space-between;

      p {
        font-size: 20px;
        text-align: center;
      }
    }
    .Element-img {
      height: 150px;
      user-select: none;
      pointer-events: none;
    }

    ::selection {
      background: white;
      color: #2e4057;
    }
  }

  .Footer {
    display: flex;
    text-align: center;
    flex-direction: column;
    height: 100svh;
    background-image: url("../public/assets/Logo.svg");
    background-repeat: space;
    background-position: center;
    background-color: #141414;
    background-size: 120px;
    position: relative;

    .Footer-Bild {
      position: absolute;
      bottom: 0;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }

    .Benni {
      position: absolute;
      top: 20vh;
      left: 10%;
      font-size: 1.5rem;
      color: white;
      font-weight: 100;
      text-align: end;
    }

    .Paul {
      position: absolute;
      top: 20vh;
      right: 10%;
      font-size: 1.5rem;
      color: white;
      font-weight: 100;
      text-align: start;
    }
  }
}
