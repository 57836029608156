* {
  font-family: "Roboto Slab", serif;
}

::selection {
  background: #2e4057;
  color: white;
}

.Home {
  background-color: #2e4057;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: none;
}

.Leistungen {
  background-color: white;
  overflow: inherit;
  height: 100svh;
  display: flex;
  padding-block: 20px;
  padding-inline: 50px;
  flex-direction: row;
  justify-content: space-between;

  h1 {
    font-size: 4rem;
    font-weight: 900;
  }
}

.Bereiche {
  background-color: #2e4057;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-block: 50px;

  h1 {
    font-size: 4rem;
    padding-bottom: 50px;
    line-height: 0;
  }
  .Elements {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 80%;
    justify-content: space-between;

    p {
      font-size: 20px;
      text-align: center;
    }
  }
  .Element-img {
    height: 150px;
    user-select: none;
    pointer-events: none;
  }

  ::selection {
    background: white;
    color: #2e4057;
  }
}

.Wir {
  background-color: white;
  height: 100svh;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: black;

  h1 {
    font-size: 4rem;
    padding-top: 50px;
    line-height: 0;
  }

  .Card-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    max-width: 400px;

    p {
      padding-top: 5%;
      font-size: 20px;
    }

    img {
      height: 300px;
      width: 300px;
      user-select: none;
      pointer-events: none;
    }
  }
}

.Points {
  margin-top: 100px;
  width: 80%;

  .UpperPoints,
  .LowerPoints {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.Dot {
  height: 25px;
  width: 25px;
  background-color: #2e4057;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.Bullet {
  display: flex;
  justify-content: row;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  width: 170px;
  line-height: 0;
}

.Kontaktieren {
  scroll-behavior: smooth;
  margin-top: 60px;
  width: 91%;
  height: 70px;
  border-radius: 12px;
  background-color: #2e4057;
  color: white;
  border: 3px solid #2e4057;
  font-size: 20px;
  line-height: 0;
  cursor: pointer;
  font-weight: 500;
  transition-duration: 0.4s;
}

.Kontaktieren:hover {
  background-color: white;
  color: #2e4057;
  border: 3px solid #2e4057;
}

.Info-selection {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 50%;
  height: 100%;
  justify-content: center;
}

.Info-selection :nth-child(1) {
  ::selection {
    background: white;
    color: #2e4057;
  }
}

.Kontakt {
  margin-top: -5px;
  display: flex;
  text-align: center;
  flex-direction: column;
  height: 100svh;
  background-image: url("../public/assets/KontaktBG.png");
  background-size: cover;

  h1 {
    @media only screen and (max-height: 670px) {
      font-size: 3rem;
         }
    @media only screen and (max-height: 500px) {
      padding-top: 20px;
     }
    font-size: 4rem;
    color: white;
    line-height: 0;
    padding-top: 50px;
  }

  .Kontakt-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-bottom: 50px;

    .Text-Container {
      padding: 10px;
      width: 90%;
    }
  }

  .Small-Input {
    @media only screen and (max-height: 670px) {
      height: 50px;
     }
    box-shadow: 0px 0px 50px 5px rgba(255, 255, 255, 0.2);
    color: white;
    background-color: rgba(0, 0, 0, 0.23);
    height: 60px;
    width: 50%;
    border: none;
    border-radius: 12px;
    font-size: 20px;
    padding-inline: 20px;
    box-sizing: border-box;
    outline: none;
  }

  .Big-Input {
    @media only screen and (max-height: 670px) {
      height: 230px;
     }
    @media only screen and (max-height: 570px) {
      height: 200px;
     }
    @media only screen and (max-height: 570px) {
      height: 170px;
     }
    outline: none;
    box-shadow: 0px 0px 50px 5px rgba(255, 255, 255, 0.2);
    color: white;
    background-color: rgba(0, 0, 0, 0.23);
    height: 250px;
    width: 50%;
    border: none;
    border-radius: 12px;
    font-size: 20px;
    padding: 20px;
    resize: none;
    box-sizing: border-box;
  }

  .Button-Container {
    width: 90%;

    .Button {
      @media only screen and (max-height: 670px) {
        height: 50px;
       }
      background-color: #2e4057;
      cursor: pointer;
      color: white;
      font-size: 20px;
      font-weight: 900;
      line-height: 0;
      border: none;
      width: 50%;
      border-radius: 12px;
      height: 60px;
    }
  }
}

.Footer {
  display: flex;
  text-align: center;
  flex-direction: column;
  height: 100svh;
  background-image: url("../public/assets/Logo.svg");
  background-repeat: space;
  background-position: center;
  background-color: #141414;
  background-size: 120px;

  position: relative;

  .Footer-Bild {
    position: absolute;
    bottom: 0;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

  }

  .Benni {
    position: absolute;
    top: 20%;
    left: 10%;
    font-size: 4rem;
    color: white;
    font-weight: 100;
    text-align: end;
  }

  .Paul {
    position: absolute;
    top: 20%;
    right: 10%;
    font-size: 4rem;
    color: white;
    font-weight: 100;
    text-align: start;
  }
}

.Impressum {
  background-color: #2e4057;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-block: 10px;

  h1 {
    font-size: 1rem;
    line-height: 0;
  }

  .Element-Div{
    width: 200px;
  }
  .Elements {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 80%;
    justify-content: space-between;

    p {
      font-size: 12px;
      text-align: center;
    }
  }
  .Element-img {
    height: 150px;
    user-select: none;
    pointer-events: none;
  }

  ::selection {
    background: white;
    color: #2e4057;
  }
}
